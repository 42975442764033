<template>
    <div class='choujiang_both width_100 height_100 flex_column position_relative'>

    <!-- <div class="width_100 flex_column choujiang_top">
        <div class="width_100" :style="{ height: jn.s+'px' }"></div>
        <div class="width_100 choujiang_nav flex_row" :style="{height: jn.t+'px'}">
            <div class="flex_row position_relative height_100 align_items_center choujiang_fanhui_par">
                <image class="choujiang_fanhui_icon" src="/bundle/common/fanhui_yuan@3x.png"></image>
                <button hover-class="none" @click="fanhuiAction" class="cover_btn zindex10"></button>
            </div>
        </div>
    </div> -->

        <div class="main_gra flex_1 over_hidden">
            <div class="main_son width_100 flex_column position_relative">

                <div class="main_bg flex_row cover_btn">
                    <img mode="" src="@/assets/img/home/choujiang_bg_1@3x.png"/>
                </div>
                
                <div class="main_other position_relative width_100 flex_column zindex10">
                <div class="zhuanpan_area flex_row position_relative" style='justify-content: center;'>
                    <!-- <image bindload="imgLoadAction" data-k="choujiang" class="" style="transition:all {{time}}; transform:rotate({{transformDeg + 'deg'}})" src="{{zhuanpan.image.url}}"></image> -->
                    <!-- <div bindload="imgLoadAction" data-k="choujiang" class="divlp" style="transition:all {{time}}; transform:rotate({{transformDeg + 'deg'}})" src="{{zhuanpan.image.url}}">
                        1111111 
                    </div> -->
                    <!-- :style="{ 'transition': 'all' + time , 'transform': rotate(transformDeg + 'deg')}" -->
                    <!-- <div bindload="imgLoadAction" data-k="choujiang" class="divlp Img"   :src="zhuanpan.image.url"  :animation="animationData"> -->
                    <div :style="{ 'transition': time , 'transform': 'rotate('+transformDeg + 'deg)'}" class="divlp Img" >

                        <img class="disk" :src="zhuanpan.image.url" mode="widthFix" style="width: 360px;height: 360px" />
                        <div class="canvas-line">
                            <!-- :style="{ 'background-color': item.color , 'transform': rotate( item.lineTurn )  }" -->
                            <div :style="{ 'background-color': item.color , 'transform': 'rotate('+ item.lineTurn + ')'  }" class="canvas-litem" v-for="item in awardsList" :key="item.index"  >
                            </div>
                            </div>
                            <div class="canvas-list">
                            <div class="canvas-item" v-for=" item in awardsList" :key="item.index">
                                <!-- :style="{'transform': rotate( item.turn ), 'background-color': item.color}" -->
                                <div :style="{'transform': 'rotate('+ item.turn +')', 'background-color': item.color}" class="canvas-item-text u-flex-col" >
                                <div style="font-size: 12px;font-weight: 400;">{{item.award}}</div>
                                <img :src=" item.logo " v-if="item.logo" mode="widthFix" style="width:30px;height: 23.5px;margin-left: 20px;"/>
                                </div>
                            </div>
                        </div>
                    </div>

                    
                    <div class="cover_btn flex_row justify_align">
                    <img class="zhizheng_pic" style="width: 82px;height: 100px; margin-bottom: 20px;" src="@/assets/img/home/zhizheng_1@3x.png"/>
                    <button @click="choujiangAction" hover-class="none" class="cover_btn zindex10" style="border:none"></button>
                    </div>
                </div>

                <div class="choujiang_info width_100 flex_row justify_center">
                    <div class="choujiang_info_sub flex_column">
                    <div class="choujiang_info_title width_100 flex_row align_items_center">
                        <div class="choujiang_info_title_name flex_1">我的抽奖机会：{{times}}次</div>
                        <!-- <div class="jihui flex_row justify_align position_relative" :v-if="zhuanpan.show_recharge==101">
                        <div class="cover_btn">
                            <img src="@/assets/img/home/jihui_bg@3x.png">
                        </div>
                        <div  class="cover_btn jihui_name_par zindex10 flex_row">
                            <div class="jihui_name">更多机会</div>
                        </div>
                        <button bindtap="gengduo" hover-class="none" class="jihui_btn cover_btn zindex10" style="border:none"></button>
                        </div> -->
                        <!-- <div class="jihui flex_row justify_align position_relative">
                        <image class="jihui_pic" src="/bundle/aixin/choujiang_icon@3x.png"></image>
                        <div class="jihui_name">更多机会</div>
                        <button bindtap="gengduo" hover-class="none" class="jihui_btn cover_btn zindex10"></button>
                        </div> -->
                    </div>

                    <div class="guize_area flex_column">
                        <div class="guize_title">抽奖规则</div>
                        <div class="guize_list">1．每日3次抽奖机会</div>
                        <div class="guize_list">2．每日零点刷新抽奖数量</div>
                        <div class="guize_list">3．周六、周日抽奖奖励翻倍</div>
                        <div class="guize_list">4．点击“更多机会”抽更多更豪大奖</div>                                       
                    </div>

                    </div>
                </div>

                </div>
            </div>
        </div>

         <!-- <p class="compont_p">绑定微信</p>
      <div class="flex_row_aic">
        <img style="width: 24px; height: 24px" src="@/assets/img/login/login_weixin.png" alt="" />
        <p>绑定微信后可使用微信扫码登录</p>
      </div>
      <el-button type="primary" class="custombtn" @click="getPoints">绑定微信</el-button>  -->
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        transformDeg: 0, // 旋转角度
        transition: 'all 3s cubic-bezier(0.005, 1.340, 1.000, 0.865)',
        time: '999s',
        jihui: 102,
        liwu_num: 0,
        runDeg: 0,
        cishu: 2,
        awardsList: [],
        zhuanpan:[],
        times:'',
     };
    },
    created() {
        this.getJiangPin()
        this.getChouJiangCiShu()
        this.getZhuanpan()
    },
    methods: {

        draw() {
            var awards = this.jiangpin;
            var awardsList = [];
            var turnNum = 1 / awards.length * 360; // 文字旋转 turn 值  
            var slpl = 22.5 * (8 / awards.length)
            // 奖项列表 
            for (var i = 0; i < awards.length; i++) {
            awardsList.push({
                turn: (i * turnNum) + slpl + 'deg',
                lineTurn: i * turnNum + turnNum / 2 + 'deg',
                award: awards[i].title,
                id: awards[i].id,
                logo: awards[i].logo,
                probability: awards[i].probability,
            });
            }
            this.awardsList = awardsList
        },

        guanbitanchuang: function(){
            this.liwu_num = 0
        },

        getZhuanpan: function(){

            this.$api("account.luckdrawluckdrawread", {luckdraw_type:101}).then((o) => {
                console.log(o)
                this.zhuanpan= o.data
            });

        },

        imgLoadAction: function (e) {
            let k = e.target.dataset.k
            this.setData({ [k]: 101 })
        },
          //点击抽奖
        choujiangAction: function(){
            if (this.transformDeg!=0){

                this.$message({
                    message: '正在抽奖中，请稍等',
                    type: 'warning'
                });

                return

            }

            //点击抽奖，并不是立马就开始动画，因为需要确定这个事情
            //用户是否有抽奖的机会
            //涉及原则：逻辑尽量在服务器写
            //前端尽量少做判断，少写核心逻辑

            this.$api("account.luckdrawdrawsave", {luckdraw_type:101}).then((o) => {
        
                console.log('抽奖',o)
                if (o.code !="1001"){
                    this.jiangpin = o.data
                    console.log(this.jiangpin)
                    this.getChouJiangCiShu()

                    this.choujiangDongHua()

                } else {
                    this.jihui= 101
                    // this.setData({ jihui: 101 })
                }

            })
           

        },

        // gengduo:function(){

        //     wx.navigateTo({
        //     url: "/pages/aidou/aidouxiangqing/choujiang/zhuanpan/zhuanpan",
        //     })

        //     // let choujiangjihui = this.selectComponent("#choujiangjihui")
        //     // choujiangjihui.kanshipin()

        // },

        cjcsdismiss: function(){
            this.jihui = 102
            this.getChouJiangCiShu()
        },

        choujiangDongHua: function(){



            
            let jiangpin = this.jiangpin

            let index = parseInt(jiangpin.prize.index) || 0

            //总共的商品数量
            let total_number = parseInt(jiangpin.total_number)

            //类型
            //101获得爱心 102获得爱心宝箱 103 7折爱心充值优惠券 104粉丝礼物
            // let type = jiangpin.prize.type

            let type = 101
            
            let item_deg = 360 / total_number

            let target_deg = item_deg * (index + 0.5)+360*2

            let item_deg_s = 360 / (total_number*2)
            let target_deg_s = item_deg_s * (index + 0.5)+360*2

            let title = "恭喜您获得:" + jiangpin.prize.title

            this.transformDegs = -target_deg_s
            this.transformDeg = -target_deg
            this.time = 'all 4s cubic-bezier(0.600, 0.4, 0.0, 0.995)'
        
            let that = this
            setTimeout(function(){

                if(type==101 || type==102){

                    that.$message({
                        message: title,
                        type: 'success'
                    });

                }

                if(type==103){

                    that.$message({
                        message: '恭喜您获得爱心充值优惠券',
                        type: 'success'
                    });

                }

                if(type==104){
                    that.liwu_num = jiangpin.prize.vote_number
                }

                
                
                //归位
                setTimeout(function(){
                    that.transformDeg = 0
                    that.time = '0s linear'
                }, 1600)

            }, 4000)
        
        },

       
        //获取奖品图片和剩余的抽奖次数
        getChouJiangCiShu: function(){
            this.$api("account.luckdrawtimesread", {luckdraw_type:101}).then((o) => {
                console.log('获取抽奖次数',o)
                this.times = o.data.times

            })
        },

    //获取送爱心弹窗数据
        getJiangPin: function () {

            this.$api("account.luckdrawprizeindex", {}).then((o) => {

                this.jiangpin = o.data.goods
                this.draw()

            })

        },

    },
  };
  </script>
  
<style>
/* .width_100{width: 100%;}
.height_100{height: 100%;}
.flex_column{flex-direction: column;display: flex;}
.position_relative{ position: relative;}
.flex_1{flex: 1;}
.over_hidden{overflow: hidden;}
.flex_row{flex-direction: row;display: flex;}
.cover_btn{width: 100%; height: 100%; position: absolute;left: 0;top: 0;}
.zindex10{z-index: 10;}
.justify_align{align-items: center;justify-content: center;}
.justify_center{justify-content: center;}
.align_items_center{align-items: center;} */

@import '/pages/common/animate.min.wxss';

::-webkit-scrollbar {
  display: none;
  width: 0;
  height: 0;
  color: transparent;
}
/* 倒计时 */

.day_num{
  width: 40rpx;
  height: 36rpx;
  line-height: 36rpx;
  font-size: 26rpx;
  color: #FFFFFF;
  /* font-weight: bold; */
  text-align: center;
  border-radius: 4rpx;
  background-color: #FE5F5F;
}
.day_font{
  margin: 0 6rpx;
  font-size: 26rpx;
  color: #333333;
}

.img_bg{
  background-color: #124691;
}
.color_red{
	color: red;
}
.color_lv{
	color: red;
}
.color_chengse{
	color: #FD7013;
}

.bg_ff_op{
  background-color: rgba(255, 255, 255, 0.7);
}
.global_block{
  margin-bottom: 20rpx;
  border-radius: 10rpx;
  overflow: hidden;
}

/* 全局视频 */
video{
  width: 100%;
  height: 100%;
}
.bg_huise{
  background-color: #F7F9FA;
}

.bg_zhuti{
  background-color: #fcfcfc;
}
.return{
  max-width: 180rpx;
}
.return_pic {
	width: 17rpx;
	height: 30rpx;
	margin-left: 30rpx;
	margin-right: 100rpx;
}
.bg_block{
  background-color: #ffffff;
  border-radius: 10rpx;
  overflow: hidden;
  margin-top: 20rpx;
}

page{
  width: 100%;
  height: 100%;
  background: #F5F5F5;
}

.blod{
  font-weight: bold;
}
.opacity_5{
  opacity: 0.5;
}


.border_bnone{
  border-bottom: none !important;
}
.lvse_color{
  color: #33BE73 !important;
}
.huise_color{
  color: #999999 !important;
}
.hongse_color{
  color: #F44D4D !important;
}


movable-view {
  pointer-events: auto;
}
movable-area {
 pointer-events: none;
}


@keyframes kuandu{
  from{
    transform: scaleX(0);
  }
  to{
    transform: scaleX(1);
  }
}
@-webkit-keyframes kuandu{
  from{
    -webkit-transform: scaleX(0);
  }
  to{
    -webkit-transform: scaleX(1);
  }
}
.global_text_center{
  text-align: center;
}
.deep_shadow{
	box-shadow:0rpx 4rpx 16rpx 0rpx rgba(151,166,189,1);
}
refreshlist{
  width: 100%;
  height: 100%;
}

.tabbar_blank{
  height: 100rpx;
}
.animated{
  animation-duration: 0.4s !important;
}
.fadeIn{
  animation-fill-mode: forwards !important;
}
.blur{
	filter: blur(12px);
  -webkit-filter: blur(12px);
  -moz-filter: blur(12px);
  -o-filter: blur(12px);
}
.bg_fff{
  background: #ffffff;
}
.border_after,.border_top{
  position: relative;
}
.border_after{
  border-bottom: solid 2rpx rgba(230,230,230,0.3);
}
.border_top{
  border-top: solid 2rpx rgba(230,230,230,0.3);
}

.shenglue_1{
	word-break: break-all;
    text-overflow: ellipsis;
    display: -webkit-box; 
    display: box; 
    -webkit-box-orient: vertical;
    line-clamp: 1; 
    -webkit-line-clamp: 1; 
    -o-line-clamp: 1; 
    overflow: hidden; 
}
.shenglue_2{
	word-break: break-all;
    text-overflow: ellipsis;
    display: -webkit-box; 
    display: box; 
    -webkit-box-orient: vertical;
    line-clamp: 2; 
    -webkit-line-clamp: 2; 
    overflow: hidden; 
}

.shenglue_3{
	word-break: break-all;
    text-overflow: ellipsis;
    display: -webkit-box; 
    display: box; 
    -webkit-box-orient: vertical;
    line-clamp: 3; 
    -webkit-line-clamp: 3; 
    overflow: hidden; 
}
.opacity0{opacity: 0;}

input,button,a,select {outline:0 none !important;}
.fixed_top{position: fixed;top: 0;left: 0}
.fixed_bottom{position: fixed;bottom: 0;left: 0}

.zindex10{z-index: 10;}

#content {background-color: #f3f3f3; width: 100%; height: 100%;}
.debug{border: solid 2px red !important;}
.debug_green{border: solid 2px green !important;}
.display_flex{display: flex;}
.flex_column{flex-direction: column;display: flex;}
.flex_row{flex-direction: row;display: flex;}
.contentModeAspectFit{object-fit:contain;}
button{cursor: pointer;}
button::after {
  border: none;
}
.position_relative{ position: relative;}
.position_absolute{ position: absolute;}
.cover_btn{width: 100%; height: 100%; position: absolute;left: 0;top: 0;}
.zindex100{
  z-index: 100;
}
.zindex5{
  z-index: 5;
}
.zindex20{
  z-index: 20;
}
.zindex15{
  z-index: 15;
}
.align_items_center{align-items: center;}
.justify_center{justify-content: center;}
.justify_align{align-items: center;justify-content: center;}
.align_self_center{align-self: center;}
input {background: transparent !important;}
.cursor_pointer{cursor: pointer;}
.minirefresh-theme-default .minirefresh-upwrap{height: 20px !important;padding: 0 !important;}
.minirefresh-totop{ display: none !important;}
.minirefresh-theme-default .status-nomore .upwrap-tips{padding: 0 !important; line-height: 30px;}
.minirefresh-wrap{position: relative !important;}

.overflow_hidden{overflow: hidden;}
.overflow_auto{
  overflow: auto;
}
.over_hidden{overflow: hidden;}
.bold{font-weight: bold;}
.width_100{width: 100%;}
.height_100{height: 100%;}
.display_hidden{opacity: 0}
.display_none{display: none !important;}
.display_show{display: flex !important;}
.flex_1{flex: 1;}
.border_none{border: none;}
.obj_contain{object-fit: contain;}
.obj_cover{object-fit: cover;}

.background_transparent {background: transparent !important;}

.overflow_scroll{overflow: scroll;-webkit-overflow-scrolling: touch;}
.overflow_auto{overflow: auto;-webkit-overflow-scrolling: touch;}
.vue-star-rating{height: 40px !important;}

.fadeIn{animation: myFadeIn 0.4s linear;  opacity: 0; animation-fill-mode: forwards;}
.myFadeIn{animation: myFadeIn 0.4s linear; animation-delay: 0.6s; opacity: 0; animation-fill-mode: forwards;}
@keyframes myFadeIn {
  0% {opacity: 0} 
  100% {opacity: 1; }
  }
.rotate{animation: rotate_anim 1s linear infinite}
@keyframes rotate_anim{
  form {-webkit-transform:rotate(360deg);
    transform:rotate(360deg);}
  to  {-webkit-transform:rotate(360deg);
    transform:rotate(360deg);}


}


canvas{z-index: 0;}

.left_0{left: 0;}
.right_0{right: 0;}
.top_0{top: 0;}
.bottom_0{bottom: 0;}



image{
  width: 100%;
  height: 100%;
}

button{
  margin: 0;
	background: none ; 
  padding: 0;
  border-radius: 0;
}
button::after{
  border: 0 !important;
}
.box_shadow{
	box-shadow:0 4rpx 12rpx 0 rgba(150,158,164,0.2);
}

.zhuanpan_area{
    margin: 140px 7.5px 0 7.5px;
    height: 360px;
    }
    .choujiang_top{
    position: fixed;
    left: 0;
    top: 0;
    z-index: 255;
    }
    .choujiang_nav{
    justify-content: flex-start;
    }
    .choujiang_fanhui_icon{
    width: 30px;
    height: 30px;
    margin: 0 15px;
    }
    .choujiang_info {
    margin-top: 7px;
    }
    .choujiang_info_title_name{
    color: #FF738C;
    font-size: 12px;
    }
    .choujiang_info_sub{
    width: 215px;
    }
    .zhizheng_pic{
    width: 82px;
    height: 100px;
    margin-bottom: 20px;
    }

    .jihui_pic{
    width: 18px;
    height: 11px;
    margin-right: 5px;
    }
    .jihui{
    width: 90px;
    height: 24px;
    line-height: 24px;
    border-radius: 14px;
    background: #FF738C;
    }
    .jihui_name{
    margin-left: 31px;
    font-size: 11px;
    color: #fff;
    }

    .guize_area{
    margin-bottom: 36px;
    }
    .guize_title{
    color: #FF738C;
    text-align: center;
    font-size: 13px;
    font-weight: bold;
    padding: 12.5px 0;
    }
    .guize_list{
    color: #353535;
    font-size: 13px;
    line-height: 23px;
    }

    .viewlp{
    width: 360px;
    height: 360px;
    position: absolute;
    background-color: red;
    /* top:180px;
    left: 180px; */
    border-radius: 50%;
    }


    .Img{
    width: 360px;
    height: 360px;
    overflow: hidden;
    border-radius: 50%;
    transform: rotate(-22.5deg);
    position: absolute;
    }


    /* 分隔线 */
    .canvas-line {
    position: absolute;
    left: 0;
    top: 0;
    width: inherit;
    height: inherit;
    z-index: 99;
    }

    .canvas-litem {
    position: absolute;
    left: 150px;
    top: 0;
    width: 3px;
    background-color: rgba(228, 55, 14, 0.4);
    overflow: hidden;
    -webkit-transform-origin: 50% 150px;
    transform-origin: 50% 150px;
    }

    .canvas-item {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    font-weight: bold;
    text-shadow: 0 1px 1px rgba(255, 255, 255, 0.6);
    text-align: center;
    padding-top: 30px;
    }



    .canvas-item-text {
    position: relative;
    display: block;
    /* padding-top: 65px; */
    color: #900000;
    font-size: 15px;
    font-weight: bold;
    margin: 5px auto;
    text-align: center;


    -webkit-transform-origin: 50% 146px;
    transform-origin: 50% 146px;
    width: 75px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    }

    .u-flex-col {
        /* #ifndef APP-NVUE */
        display: flex;
        /* #endif */
        flex-direction: column;
    }

</style>

  <style lang="less" scoped>
    .flex_row_aic {
        margin: 25px 0;
        img {
        margin-right: 10px;
        }
    }

    .custombtn {
        margin-left: 30px;
    }
  </style>
  
